import React from 'react';
import {Box} from "@mui/system";
import {color} from "src/Theme";
import {styled} from "@mui/material/styles";
import {Link} from "react-router-dom";
import {Helmet} from 'react-helmet';

const LinkStyled = styled(Link)(() => ({
    fontSize: '16px',
    fontWeight: 900,
    textDecoration: 'none',
    color: color.textPrimary
}));

function Privacy() {

    React.useEffect(() => {
        let element = document.getElementsByClassName("scrollable");
        element[0].scrollTo({top: 0, behavior: 'auto'});
    }, []);

    return (
        <>
            <Helmet>
                <title>Politique de Confidentialité</title>
                <meta name="description" content="Consultez les politiques de confidentialité."/>
                <meta name="keywords" content="hébergement web, hébergeur, makersystem, pro, professionnel, AI, IA, intelligence artificielle, serveur, site internet, nom de domaine, support, sécurité"/>
            </Helmet>

            <Box sx={{maxWidth: '1550px', margin: 'auto', color: color.textPrimary, padding: '15px'}}>
                <h1>Politique de Confidentialité</h1>
                <p>Dernière mise à jour : 1 janvier 2023</p>

                <p>
                    La protection de la confidentialité et de l'intégrité des données de nos clients est essentielle pour Maker System.
                    Ainsi, nous nous assurons de restreindre le traitement des données personnelles à ce qui est requis pour offrir nos services et de respecter pleinement les droits garantis par le Règlement général sur la protection des données européen (RGPD) du 27 avril 2016.
                    Globalement, sauf pour des besoins spécifiques mentionnés ci-après, Maker System n'accède pas aux données que vous choisissez d'héberger sur notre Application.
                    En aucun cas, Maker System n'utilise les données de vos clients et utilisateurs.
                    Cette politique de confidentialité explique les données que nous traitons, les raisons de ces traitements et les droits dont vous bénéficiez.
                    Elle s'applique aux données personnelles de nos clients collectées lors de l'utilisation de l'Application Maker System,
                    accessible sur le domaine maker-system.com. Toutefois, chaque client de Maker System utilisant nos services d'hébergement pour exécuter une application est responsable des traitements des données de ses utilisateurs,
                    sous réserve de l'Accord de traitement des données (DPA), qui fait partie intégrante du Contrat entre vous et Maker System.
                </p>


                <h2>Les traitements mis en oeuvre par Maker System</h2>
                <p>Maker System traite des données personnelles pour différentes finalités, en particulier :</p>
                <p>
                    Lorsque vous visitez l'Application, y compris notre site maker-system.com,
                    Maker System collecte des données qui sont nécessaires pour détecter les anomalies,
                    suivre les visites sur les différentes pages et composants de l'Application,
                    vérifier si le contenu répond aux attentes des visiteurs et utilisateurs, et optimiser les performances du site internet et de l'Application.
                </p>
                <p>Ces données strictement nécessaires à la bonne administration de l'Application comprennent :</p>
                <ul>
                    <li>La première moitié de votre adresse IP (par ex. 192.168.xxx.xxx) ;</li>
                    <li>La version de votre navigateur ;</li>
                    <li>Votre système d’exploitation (par ex. Windows, Mac OS, Linux…) ;</li>
                    <li>Le type de terminal (par ex. téléphone, ordinateur…) et sa résolution d’écran ;</li>
                    <li>L’URL des pages que vous visitez ou le type d’action que vous effectuez ;</li>
                    <li>Un identifiant pseudonyme.</li>
                </ul>
                <p>Les données sont stockées et traitées pendant 180 jours sur les serveurs de Maker System et ne sont pas partagées avec des tiers.</p>


                <h3>Sécurisation de l'Application, gestion des erreurs et journalisation des accès</h3>
                <p>
                    Dans le but de sécuriser l'Application en détectant les tentatives d'intrusion, de fraude ou d'attaques de déni de service,
                    et pour repérer toute anomalie éventuelle dans son fonctionnement, Maker System utilise des procédés techniques qui impliquent le traitement de données à caractère personnel telles que :
                </p>
                <ul>
                    <li>Votre adresse IP ;</li>
                    <li>Le User Agent de votre navigateur ;</li>
                    <li>Votre identifiant de connexion et mot de passe de manière crypté ;</li>
                    <li>Le code source de vos Applications (par ex. pour la détection automatisée de malwares) ;</li>
                    <li>Une journalisation de vos activités et événements sur l'Application (tels que les journaux d’erreurs système)</li>
                </ul>

                <p>Ces traitements sont effectués sur la base de l'intérêt légitime de Maker System à sécuriser ses services et les données de ses clients, ainsi qu'à garantir le bon fonctionnement de l'Application conformément à l'article 6.1.f du RGPD.</p>
                <p>
                    De plus, afin de se conformer à ses obligations légales de conservation des données de connexion, Maker System conserve les données susmentionnées relatives à la création,
                    la modification ou la suppression de contenus (code source) pendant un an.
                    Il est rappelé que le Client est responsable de la collecte et de la conservation des données de connexion liées aux contenus de ses propres clients,
                    conformément à ses propres obligations légales.
                </p>


                <h3>Administration et facturation de votre compte Client</h3>
                <p>Lors de votre inscription sur l'Application et de son utilisation, vous pouvez transmettre à Maker System des données liées à votre compte client, telles que : </p>
                <ul>
                    <li>Votre nom ;</li>
                    <li>Votre état civil ;</li>
                    <li>Votre adresse e-mail ;</li>
                    <li>Votre numéro de téléphone ;</li>
                    <li>Votre adresse postale ;</li>
                    <li>Vos identifiants ;</li>
                    <li>Les organisations auxquelles vous êtes rattaché(e) ;</li>
                    <li>Vos applications et données associées.</li>
                </ul>
                <p>
                    Les données que vous communiquez à Maker System dans le cadre de votre inscription et de l'utilisation de l'Application sont conservées tout au long de la durée de votre contrat avec nous,
                    conformément à l'article 6.1.b du RGPD, dans le but de gérer et de sécuriser votre compte client, ainsi que pour la facturation de nos services.
                    Ces données peuvent également être conservées au-delà de la durée du contrat,
                    conformément aux délais de conservation exigés par nos obligations légales et réglementaires, sur la base de l'article 6.1.c du RGPD.
                </p>
                <p>
                    Le mot de passe associé à votre identifiant n'est jamais stocké en clair par Maker System et ne vous sera jamais demandé ailleurs que sur l'interface de connexion de l'Application,
                    qui est exclusivement accessible depuis le site internet de Maker System. Vous pouvez activer l'authentification à double facteur pour renforcer la sécurité de l'accès à votre compte.
                    Maker System vous recommande de le faire afin de réduire les risques d'accès non autorisé à vos données par des tiers.
                </p>


                <h3>Support technique et commercial</h3>
                <p>
                    Afin de répondre aux demandes d'assistance technique ou commerciale de ses clients,
                    Maker System peut traiter les données suivantes, conformément à l'exécution du contrat entre vous et Maker System, sur la base de l'article 6.1.b du RGPD :
                </p>
                <ul>
                    <li>Votre adresse IP ;</li>
                    <li>Les organisations auxquelles votre compte est rattaché ;</li>
                    <li>Votre adresse e-mail ;</li>
                    <li>Votre numéro de téléphone ;</li>
                    <li>Le contenu de vos messages ;</li>
                    <li>La date et l’heure de votre dernière activité.</li>
                </ul>


                <h2>Mesures de sécurisation</h2>
                <p>
                    Afin de sécuriser les données que vous nous confiez, Maker System utilise des mesures techniques et organisationnelles telles que le chiffrement et la limitation des droits d'accès aux seuls collaborateurs et prestataires autorisés à y accéder dans le cadre de leurs missions légitimes.
                </p>
                <p>
                    La sécurité des données est une priorité dans la conception, le développement et les mises à jour des services de Maker System. Des exigences strictes de sécurité des données sont intégrées dès le processus de création des services, et des évaluations et améliorations continues sont effectuées tout au long de leur évolution.
                </p>
                <p>
                    Les données sont hébergées exclusivement dans des serveurs gérés par Maker System, situés dans des centres de données hautement sécurisés que vous pouvez choisir.
                </p>


                <h2>Exercice de vos droits</h2>
                <p>Conformément au RGPD, vous disposez sur vos données personnelles des droits suivants :</p>

                <ul>
                    <li>droit d’accès (article 15 RGPD), de rectification (article 16 du RGPD), de mise à jour, de complétude de vos données ;</li>
                    <li>droit de retirer à tout moment votre consentement (article 13.2.c du RGPD) ;</li>
                    <li>droit à la limitation du traitement de vos données (article 18 du RGPD) ;</li>
                    <li>droit d’opposition au traitement de vos données (article 21 du RGPD) ;</li>
                    <li>droit à la portabilité des données que vous nous avez fournies, lorsque vos données font l’objet de traitements automatisés fondés sur votre consentement ou sur un contrat (article 20 du RGPD) ;</li>
                    <li>droit à l’effacement de vos données à caractère personnel (article 17 du RGPD) ;</li>
                    <li>Conformément à l’article 12 du RGPD, chaque demande d’exercice de droits doit être accompagnée des informations pertinentes permettant de démontrer votre identité. En cas de doute raisonnable, Maker System pourra demander que soient fournies des informations supplémentaires nécessaires pour confirmer l’identité de la personne demandant l’exercice de ses droits. Les données personnelles relatives à l’exercice de ces droits sont conservées pendant une durée de 1 an à compter de la date de la réponse adressée par Maker System.</li>
                </ul>

                <p>Vous bénéficiez également du droit de nous communiquer des directives concernant le sort de vos données après votre décès et de choisir que nous communiquions (ou non) vos données à un tiers que vous aurez préalablement désigné. En cas de décès et à défaut d’instructions de votre part, nous nous engageons à détruire vos données, sauf lorsque leur conservation s’avère nécessaire à des fins probatoires ou pour répondre à une obligation légale.</p>

                <p>Vous pouvez exercer ces droits en nous écrivant à contact@maker-system.com ou en nous envoyant un courrier postal à l’adresse indiquée <LinkStyled to="/terms">dans nos mentions légales</LinkStyled>, à l’attention de la Direction Juridique.</p>
            </Box>
        </>
    );
}

export default Privacy;
