import React, {lazy, Suspense} from 'react';
import {Box, useTheme} from "@mui/system";
import {Fade, Typography, useMediaQuery} from "@mui/material";
import FooterIllustration from "./Component/FooterIllustration";
import {color} from "src/Theme";
import {styled} from "@mui/material/styles";
import {Helmet} from 'react-helmet';
import {Link} from "react-router-dom";

const LoginIllustration = styled('img')(({theme}) => ({
    zIndex: 2,
    maxHeight: 750,
    marginTop: theme.spacing(12),
    marginBottom: theme.spacing(12),
    [theme.breakpoints.down(1540)]: {
        maxHeight: 650
    },
    [theme.breakpoints.down('lg')]: {
        maxHeight: 650
    }
}));
const RightWrapper = styled(Box)(({theme}) => ({
    width: '100%',
    [theme.breakpoints.up('md')]: {
        maxWidth: 450
    },
    [theme.breakpoints.up('lg')]: {
        maxWidth: 600
    },
    [theme.breakpoints.up('xl')]: {
        maxWidth: 750
    }
}));
const HomeNext = lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import("./HomeNext")), 500);
    });
});
const LinkStyled = styled(Link)(() => ({
    textDecoration: 'none',
}));

function Home() {
    const theme = useTheme();
    const hidden = useMediaQuery(theme.breakpoints.down('md'));

    React.useEffect(() => {
        let element = document.getElementsByClassName("scrollable");
        element[0].scrollTo({top: 0, behavior: 'auto'});
    }, []);

    return (
        <Box>
            <Helmet>
                <title>Maker System - Hébergeur Web des professionnels</title>
                <meta name="description" content="Une solution d'hébergement Web et d'infogérance basé sur l'intelligence artificielle (IA) pour assurer la sécurité et la disponibilité de vos applications."/>
                <meta name="keywords" content="hébergement web, hébergeur, makersystem, pro, professionnel, AI, IA, intelligence artificielle, serveur, site internet, nom de domaine, support, sécurité"/>
            </Helmet>

            <Box>
                <Box sx={{maxWidth: '1550px', margin: 'auto'}}>
                    <Box sx={{
                        display: 'flex',
                        minHeight: 'calc(100vh - 69px)',
                        overflowX: 'hidden',
                        position: 'relative'
                    }}>
                        <RightWrapper>
                            <Fade in={true} {...{timeout: 750}}>
                                <Box
                                    sx={{
                                        padding: '0 25px',
                                        height: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <Box sx={{width: '100%', maxWidth: 500, paddingBottom: '125px'}}>
                                        <Typography component={'h1'} sx={{
                                            fontSize: '40px',
                                            color: color.textPrimary,
                                            lineHeight: '40px'
                                        }}>Développez, nous nous occupons du reste !</Typography>
                                        <br/>

                                        <Typography sx={{
                                            fontSize: '22px',
                                            color: color.textSecondary,
                                            lineHeight: '30px'
                                        }}>
                                            Maker System offre un service d'hébergement Web innovant intégrant l'intelligence artificielle, assurant la gestion et la sécurité de vos environnements.
                                            Ainsi, vous pouvez vous focaliser entièrement sur le cœur de votre activité professionnelle.
                                        </Typography>

                                        <br/>
                                        <LinkStyled to={'/contact'}>
                                            <button type="button" className="btn">
                                                <strong>NOUS CONTACTER</strong>
                                                <div id="container-stars">
                                                    <div id="stars"/>
                                                </div>

                                                <div id="glow">
                                                    <div className="circle"/>
                                                    <div className="circle"/>
                                                </div>
                                            </button>
                                        </LinkStyled>
                                    </Box>
                                </Box>
                            </Fade>
                        </RightWrapper>
                        {!hidden ? (
                            <Box sx={{
                                flex: 1,
                                display: 'flex',
                                position: 'relative',
                                alignItems: 'center',
                                borderRadius: '20px',
                                justifyContent: 'center',
                                margin: '2rem 0rem 2rem 2rem'
                            }}>
                                <Fade in={true} {...{timeout: 750}}>
                                    <LoginIllustration alt='login illustration' src={'/images/hosting_1.webp'}/>
                                </Fade>
                            </Box>) : null}
                    </Box>
                </Box>
                <FooterIllustration/>
            </Box>

            <Suspense fallback={<div></div>}>
                <HomeNext/>
            </Suspense>
        </Box>
    );
}

export default Home;
