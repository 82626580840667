import React from 'react';
import {LoadingButton} from '@mui/lab';
import {color} from "src/Theme";

function ButtonComponent(props) {
    return (
        <LoadingButton
            type={props.type ?? 'button'}
            loading={props.loading}
            disabled={props.disabled}
            variant={props.outlined ? 'outlined' : 'contained'}
            sx={
                {
                    width: '100%',
                    fontSize: props.fontSize ?? '14px',
                    '& .MuiCircularProgress-root': {
                        color: color.primary
                    },
                    ...(
                        props.color ? {
                            backgroundColor: props.outlined ? 'unset' : props.color,
                            color: props.loading ? color.textPrimary : (props.outlined ? props.color : '#FFFFFF'),
                            borderColor: props.color,
                            '&:hover': {
                                backgroundColor: props.outlined ? 'unset' : props.color,
                            }
                        } : {
                            backgroundColor: props.outlined ? 'unset' : color.primary,
                            color: props.loading ? color.textPrimary : (props.outlined ? props.color : '#FFFFFF'),
                            '&:hover': {
                                backgroundColor: props.outlined ? 'unset' : color.primaryHover,
                            }
                        }
                    )
                }
            }
            onClick={props.onClick}>
            {props.label}
        </LoadingButton>
    );
}

export default ButtonComponent;
