import useMediaQuery from '@mui/material/useMediaQuery'
import {styled, useTheme} from '@mui/material/styles'

const MaskImg = styled('img')(({theme}) => ({
    bottom: 0,
    height: 300,
    width: '100%',
    position: 'absolute',
    [theme.breakpoints.down(1540)]: {
        height: 250
    }
}));

const FooterIllustration = props => {
    const {image, height, className} = props;
    const theme = useTheme();
    const hidden = useMediaQuery(theme.breakpoints.down('md'));

    if (!hidden) {
        return (
            <>
                {!image ? (
                    <MaskImg
                        alt='mask'
                        className={className}
                        {...(height && {height})}
                        src={`/images/mask-dark.webp`}
                    />
                ) : typeof image === 'string' ? (
                    <MaskImg alt='mask' src={image} className={className} {...(height && {height})} />
                ) : (
                    image
                )}
            </>
        );
    }
    else {
        return null
    }
}

export default FooterIllustration;
