import React from 'react';
import {Box} from "@mui/system";
import {color} from "src/Theme";
import {Helmet} from "react-helmet";

function Terms() {

    React.useEffect(() => {
        let element = document.getElementsByClassName("scrollable");
        element[0].scrollTo({top: 0, behavior: 'auto'});
    }, []);

    return (
        <>
            <Helmet>
                <title>Conditions Générales d’Utilisation</title>
                <meta name="description" content="Consultez les conditions générales d’utilisation."/>
                <meta name="keywords" content="hébergement web, hébergeur, makersystem, pro, professionnel, AI, IA, intelligence artificielle, serveur, site internet, nom de domaine, support, sécurité"/>
            </Helmet>

            <Box sx={{maxWidth: '1550px', margin: 'auto', color: color.textPrimary, padding: '15px'}}>
                <h1>Conditions Générales d’Utilisation</h1>
                <p>Dernière mise à jour : 15 Mai 2023</p>


                <h2>1 - Les traitements mis en œuvre par Maker System</h2>
                <p>
                    1.1. Maker System fournit aux développeurs professionnels et aux entités morales une Application d'hébergement (ci-après “la Plateforme”), qui leur offre la
                    possibilité de déployer et gérer facilement leurs applications en utilisant des logiciels de déploiement et des composants supplémentaires (bases de données,
                    moteurs de recherche, systèmes de fichiers, etc.) configurés, optimisés et mis à jour automatiquement. La Plateforme permet aux utilisateurs de se focaliser sur
                    le développement et l'exploitation de leurs produits et services, sans se soucier de la gestion et de l'optimisation de l'infrastructure d'hébergement.
                </p>
                <p>
                    La Plateforme inclut des produits créés et vendus par Maker System, qui sont proposés et commercialisés selon leurs propres conditions à travers Maker System.
                </p>
                <p>
                    Dans le contexte des présentes conditions, une "application" est définie comme tout logiciel déployé sur la Plateforme, dont le code source est hébergé et exécuté sur les serveurs de Maker System.
                </p>

                <p>
                    1.2. La Plateforme accessible à l’adresse app.maker-system.com est exploitée par Maker System,
                    société par actions simplifiée immatriculée au registre du commerce de Marseille sous le numéro 914 990 650,
                    dont le siège social est situé au 78 Avenue des Champs-Élysées, Bureau 326, 75008 Paris, FRANCE (ci-après “Maker System” ou “nous”).
                </p>

                <p>
                    1.3. Les conditions d'utilisation de la Plateforme sont composées des présentes Conditions Générales,
                    éventuellement des Conditions Particulières, ainsi que de tout document contractuel mentionné par lien hypertexte, tels que notre Politique de Confidentialité,
                    notre Accord de traitement de données (DPA), notre Grille Tarifaire (ci-après, collectivement, "les Conditions").
                </p>

                <p>
                    1.4. Les Conditions ont pour objectif de régir les relations entre Maker System et chaque utilisateur de la Plateforme (ci-après, indifféremment, "le Client", "l'Utilisateur" ou "vous").
                    En vous connectant à la Plateforme et en demandant la création d'un compte utilisateur,
                    vous acceptez d'être lié à nous par les Conditions dès la confirmation de la création de votre compte via le lien reçu à l'adresse e-mail que vous avez fournie,
                    et ce jusqu'à la résiliation du contrat ainsi conclu entre vous et nous ("le Contrat").
                    Le Contrat peut également être établi par la signature d'un bon de commande auquel les présentes Conditions sont annexées,
                    et prend effet à la date de signature du bon de commande le plus ancien en vigueur.
                </p>


                <h2>2 - Licences d’utilisation</h2>
                <p>
                    2.1. Sous réserve et en échange du respect total des Conditions, Maker System vous accorde, pour la durée du Contrat et à l'échelle mondiale,
                    un droit non exclusif, personnel, non sous-licenciable d'utiliser la Plateforme pour tester et déployer vos applications,
                    ainsi que pour donner accès à des tiers (vos clients) aux applications que vous décidez de déployer.
                </p>
                <p>
                    2.2. Sauf indication contraire et à l'exception des logiciels distribués sous des licences spécifiques (par exemple, du code open source sous licence libre),
                    le code source de la Plateforme appartient exclusivement à Maker System,
                    et vous n'avez aucun droit de le modifier, le distribuer, le copier, l'adapter, effectuer de la rétro-ingénierie,
                    le décompiler ou tenter de l'extraire de quelque manière que ce soit. Si des actes de rétro-ingénierie étaient nécessaires pour permettre l'interopérabilité de la Plateforme
                    avec une autre application, ils ne seraient autorisés que si Maker System n'a pas répondu dans un délai raisonnable à une demande expresse de communication d'interfaces de programmation (API),
                    dans laquelle vous nous auriez indiqué les données auxquelles vous souhaitez accéder et le format d'entrée accepté par les interfaces de programmation de la Plateforme tierce.
                </p>
                <p>
                    2.3. Vous conservez l'ensemble des droits de propriété intellectuelle liés aux données que vous possédez et que vous déposez sur la Plateforme.
                    Toutefois, pour les besoins stricts du fonctionnement de la Plateforme et de l'exécution des présentes Conditions,
                    vous accordez à Maker System, à l'échelle mondiale et pour la durée du Contrat, un droit non exclusif, transférable et sous-licenciable de reproduire, adapter, modifier,
                    publier, mettre à la disposition du public, ou utiliser autrement toute donnée et toute application que vous déposez sur la Plateforme. Dans la mesure autorisée par la loi,
                    Maker System ne donnera pas accès à ces données et applications à des tiers, sauf celles que vous avez choisi de partager avec des tiers dans le cadre de votre utilisation de la Plateforme.
                </p>
                <p>
                    2.4. En vous inscrivant sur la Plateforme et en déployant une Application, vous accordez à Maker System le droit irrévocable d'utiliser,
                    à l'échelle mondiale et sur tous supports physiques ou immatériels, vos marques commerciales, logos, dénominations et autres signes distinctifs pour
                    promouvoir votre utilisation des services de Maker System, notamment sur son site web, ses présentations commerciales et ses supports de communication.
                    Cette autorisation est valable pour toute la durée du Contrat et pour les 2 (deux) années suivant la résiliation du Contrat.
                </p>


                <h2>3 - Vos obligations et interdictions</h2>

                <p>
                    3.1. Vous devez utiliser la Plateforme de manière adéquate, raisonnable, en conformité avec la législation en vigueur et les Conditions,
                    y compris la Politique d'Utilisations Acceptables que vous devez consulter régulièrement.
                    Il est notamment interdit d'utiliser la Plateforme pour violer les droits de tiers, diffuser des contenus incitant à la commission de crimes ou délits,
                    promouvoir la haine raciale, le suicide, les crimes contre l'humanité, le terrorisme ou diffuser des contenus pédopornographiques.
                </p>
                <p>
                    3.2. Vous êtes seul responsable de votre utilisation de la Plateforme et de l'utilisation de vos applications par vous-même ou par des tiers.
                    Vous devez prendre toutes les précautions et mesures nécessaires pour éviter une utilisation non conforme aux Conditions.
                    Maker System n'a aucune obligation de surveillance, et l'absence de notification de la part de Maker System concernant une activité contraire aux Conditions ne constitue pas une acceptation de cette activité.
                </p>
                <p>
                    3.3. Vous devez fournir aux tiers une information facilement accessible et lisible pour vous contacter afin de signaler d'éventuels contenus illicites accessibles via votre application.
                </p>
                <p>
                    3.4. Vous devez respecter les conditions d'utilisation des services tiers disponibles sur la Plateforme.
                </p>
                <p>
                    3.5. Vous devez veiller à ne pas autoriser l'accès de tiers à votre compte utilisateur sur la Plateforme.
                    Il vous incombe de garder vos identifiants strictement confidentiels et de prévenir Maker System si vous soupçonnez qu'un tiers pourrait accéder à vos données,
                    par exemple si vous pensez que votre mot de passe a été divulgué.
                </p>
                <p>
                    3.6. Vous devez informer Maker System dès que possible des anomalies et dysfonctionnements éventuellement rencontrés lors de l'utilisation de la Plateforme
                    (disponibilité, stabilité, facturation, etc.) et contribuer à leur résolution si nécessaire.
                </p>
                <p>
                    3.7. Vous devez nous fournir des informations exactes et à jour pour nous permettre de vous contacter.
                </p>


                <h2>4. Maintenance et Support</h2>

                <p>
                    4.1. Maker System fournit une assistance technique à distance (« Support ») pour votre utilisation de la Plateforme par messagerie électronique intégrée à la Plateforme ou,
                    sous réserve de souscription à une option de service, par téléphone aux heures indiquées sur le site de la Plateforme.
                    Seul le titulaire du compte ou une personne explicitement désignée par lui peut utiliser ce Support.
                </p>
                <p>
                    4.2. Le coût du Support est inclus dans le prix de facturation de la Plateforme pour toute demande occasionnelle de conseils sur l'utilisation de la Plateforme ou
                    pour toute intervention permettant de rétablir une utilisation normale de la Plateforme.
                    Les demandes de services spécifiques peuvent être facturées après accord exprès du Client.
                </p>
                <p>
                    4.3. Maker System s'efforce de répondre rapidement aux demandes envoyées au Support.
                    Toutefois, le Client reconnaît et accepte que, sauf dans les conditions spécifiées dans la Politique de Support Premium,
                    Maker System ne garantit pas de délai de réponse et de prise en charge des incidents signalés.
                </p>
                <p>
                    4.4. Un "Incident Critique" est un incident qui empêche totalement l'utilisation de la Plateforme.
                    Un "Incident Majeur" est un incident qui dégrade ou restreint considérablement les performances et/ou une ou plusieurs fonctionnalités principales de la Plateforme.
                </p>
                <p>
                    4.5. Le Client reconnaît et accepte que pour résoudre, prévenir ou mettre fin à un incident,
                    Maker System peut accéder à toutes ses données et applications et effectuer toute modification,
                    désactivation ou suppression jugée nécessaire pour résoudre l'incident.
                    Maker System peut également effectuer des mises à jour de la Plateforme et des logiciels nécessaires au bon fonctionnement de l'application du Client.
                </p>
                <p>
                    4.6. Le Client reconnaît que la Plateforme est fournie en l'état et qu'il est impossible pour
                    Maker System de garantir l'absence d'anomalies ou de promettre la correction de toutes les anomalies, erreurs,
                    incidents ou autres problèmes pouvant affecter l'utilisation de la Plateforme.
                    Le Client reconnaît également que Maker System ne peut garantir le fonctionnement optimal de la Plateforme sur tous les systèmes informatiques existants,
                    et qu'il doit veiller à utiliser un système compatible avec la Plateforme et ses évolutions constantes.
                </p>


                <h2>5. Garantie de disponibilité (SLA)</h2>

                <p>
                    5.1. Sauf mention contraire plus favorable dans le cadre d'une option spécifique de garantie de niveau de service souscrite par le Client,
                    Maker System assure un taux de disponibilité moyen de 99,9% par an pour la Plateforme pendant toute la durée du Contrat, selon les conditions détaillées dans cette section.
                </p>
                <p>
                    5.2. La "Durée d'indisponibilité" est la période pendant laquelle la Plateforme a subi un Incident Critique ou un Incident Majeur en raison d'une cause imputable
                    à Maker System ("Durée d'indisponibilité"). Sont exclus de la Durée d'indisponibilité les interruptions de fonctionnement de tout ou partie du réseau Internet
                    hors du contrôle de Maker System, ainsi que les indisponibilités dues à des opérations de maintenance demandées par le Client au Support ou programmées avec un préavis
                    d'au moins dix (10) jours.
                </p>
                <p>
                    5.3. Si l'indisponibilité dépasse le taux mentionné à l'article 5.1, le Client peut demander à Maker System une indemnité maximale pour une période donnée
                    (au minimum une période de relevé de consommation, soit un mois calendaire) calculée selon la formule P = Tindisp x R x 2 dans laquelle :
                </p>
                <ul>
                    <li>P = montant de la pénalité en euros ;</li>
                    <li>Tindisp = taux d'indisponibilité sur la période considérée en divisant le nombre de minutes consécutives d'indisponibilité de la Plateforme par le nombre total de minutes de la période ;</li>
                    <li>R = montant de la redevance facturée et intégralement payée par le Client au cours des douze (12) mois précédant le début de la période d'indisponibilité.</li>
                </ul>
                <p>
                    En tout état de cause, le montant de cette indemnité est plafonné à 10% du montant total de la redevance facturée au Client
                    au cours des douze (12) mois du Contrat précédant le début de la période d'indisponibilité. Si ce plafond est atteint,
                    le Client peut résilier le Contrat sans préavis et avec effet immédiat.
                </p>
                <p>
                    5.4. Le Client reconnaît et accepte que l'indemnité ainsi calculée couvre l'ensemble des préjudices pouvant être attribués à
                    Maker System en raison de cette indisponibilité de la Plateforme,
                    et qu'aucune compensation supplémentaire ou réduction des montants facturés ne pourra être réclamée.
                    Le Client est responsable de documenter les preuves des Durées d'indisponibilité et de notifier Maker System dès que la Plateforme devient indisponible.
                    Pour être recevable, toute demande d'indemnité doit être soumise au plus tard deux (2) mois après la fin de l'indisponibilité.
                </p>


                <h2>6. Prix et paiement</h2>
                <p>
                    6.1. L'accès à la Plateforme nécessite le paiement d'un montant, appelé "Redevance",
                    qui varie en fonction des services choisis. En passant commande, le Client accepte les montants associés à la Redevance, hors taxes.
                </p>
                <p>
                    6.2. La Redevance est payable mensuellement et à l'avance pour chaque service souscrit.
                    Un mois entamé doit être payé, mais si nécessaire, la Redevance sera calculée au prorata pour les jours restants.
                </p>
                <p>
                    6.3. Maker System peut exiger, à sa discrétion, que le Client fournisse un moyen de paiement valide et des informations d'identification.
                    L'accès à la Plateforme peut être suspendu jusqu'à la réception et le traitement des informations requises.
                </p>
                <p>
                    6.4. Maker System peut modifier sa grille tarifaire à tout moment.
                    Le Client sera informé par e-mail de tout changement de prix de la Redevance au moins 30 jours avant l'application du nouveau tarif.
                    Si le Client continue d'utiliser la Plateforme après cette date, il accepte le nouveau prix, mais peut résilier immédiatement sans préavis ni indemnités
                    entre la date de notification et la date d'application du nouveau prix.
                </p>
                <p>
                    6.5. Si le Client retarde le paiement de plus de 30 jours après la date d'échéance d'une facture, cela constitue une violation grave de ses obligations.
                    Maker System se réserve alors le droit de suspendre les Services sans préavis selon l'article 7.
                    En cas de non-paiement, des intérêts de retard seront automatiquement appliqués jusqu'au paiement complet à un taux égal à 5 fois le taux d'intérêt légal.
                    Maker System se réserve également le droit de réclamer des dommages-intérêts et autres frais de recouvrement, ainsi qu'une indemnité forfaitaire de 50 euros.
                </p>


                <h2>7. Suspension des Services</h2>
                <p>
                    7.1. La suspension des services désigne la désactivation totale ou partielle des droits d'accès et d'utilisation des fonctionnalités
                    de la plateforme, incluant, sans s'y limiter, l'accès à la console, l'exécution d'applications et d'add-ons, ou l'accès au code source,
                    documents et autres données sur la plateforme. La suspension des services peut être temporaire ou définitive, cette dernière entraînant
                    la résiliation automatique du contrat.
                </p>
                <p>
                    7.2. En cas de non-respect des conditions ou de suspicion raisonnable de non-respect, Maker System se réserve le droit de suspendre les services.
                    Le client sera informé de cette décision par e-mail à l'adresse associée au compte concerné et aura la possibilité de présenter ses observations pour contester
                    la suspension.
                </p>
                <p>
                    7.3. La suspension des services peut devenir définitive dans les cas suivants :
                </p>
                <ul>
                    <li>Le client ne remédie pas aux manquements dans le délai indiqué dans la notification de suspension ;</li>
                    <li>Le client a abusé des services de manière répétée ou n'a pas respecté les conditions ;</li>
                    <li>Le client a gravement enfreint l'une des obligations mentionnées dans les conditions.</li>
                </ul>
                <p>
                    7.4. Maker System se réserve le droit de refuser, à sa discrétion, tout nouveau contrat sollicité par vous ou tout contrat dont Maker System a des raisons légitimes de penser qu'il est directement ou indirectement lié à un client ayant été suspendu.
                </p>


                <h2>8. Garanties et limitations de responsabilité</h2>
                <p>
                    8.1. Maker System garantit qu'elle détient les droits pour accorder ou sous-licencier l'utilisation de tous les logiciels tiers accessibles sur la plateforme
                    lors de la souscription du client aux services utilisant ces logiciels, et protège le client contre toute réclamation de tiers à cet égard.
                    Toutefois, le client reconnaît et accepte que les conditions des licences accordées par des tiers peuvent être modifiées ou retirées à tout moment,
                    sans engager la responsabilité de Maker System. Il incombe au client de vérifier la conformité de ses applications avec les termes et conditions des licences de
                    logiciels tiers.
                </p>
                <p>
                    8.2. Le client est seul responsable de la sauvegarde de ses données et de la redondance de ses services. Hormis les cas couverts par la garantie de disponibilité
                    mentionnée à l'article 5, Maker System ne garantit pas que la plateforme fonctionnera sans défaut, interruption ou erreur,
                    et aucune indemnisation ne pourra être demandée en cas de dommage direct ou indirect résultant d'une défaillance de la plateforme,
                    telle qu'une perte ou fuite de données, perte de clientèle, perte de chiffre d'affaires, atteinte à l'image ou perte d'opportunité.
                </p>
                <p>
                    8.3. En tout état de cause, la responsabilité financière totale de Maker System est limitée au montant des redevances payées par le client au cours des douze (12) mois
                    précédant le premier fait générateur du ou des dommages invoqués, dans la mesure permise par la loi.
                </p>
                <p>
                    8.3. Vous garantissez pleinement Maker System et ses affiliés contre toute réclamation et procédure de tiers liée à votre utilisation non conforme de la plateforme,
                    pour l'ensemble des dépenses et dommages subis ou encourus par Maker System, notamment ceux provoqués par le contenu ou l'utilisation de vos applications.
                </p>
                <p>
                    8.4. Les parties ne seront pas considérées comme défaillantes dans l'exécution du contrat en cas de retard ou d'inexécution liés à un cas de force majeure
                    généralement reconnu par la jurisprudence, par exemple en cas de catastrophe naturelle ou climatique, conflit armé, acte de terrorisme, émeute, épidémie, embargo,
                    inondation, pénurie d'énergie ou de matières premières, coupure ou restriction des réseaux Internet, etc. Le cas de force majeure suspend les obligations des
                    parties dont l'exécution est empêchée.
                </p>


                <h2>9. Propriété intellectuelle et Confidentialité</h2>
                <p>
                    9.1. À l'exception des données du client et dans la mesure permise par la loi, tous les éléments constituant la plateforme
                    (tels que code source, images, textes, vidéos, apparence graphique, logos, etc.) appartiennent à Maker System, à ses concédants ou à des tiers ayant autorisé leur
                    exploitation, et sont protégés par des droits de propriété intellectuelle et industrielle.
                    Lorsque des licences tierces s'appliquent, par exemple pour des contenus distribués sous licence open source, Maker System renvoie à ces licences.
                </p>
                <p>
                    9.2. "Maker System" est une marque déposée par Maker System SAS. L'utilisation de la marque ou d'un logo de Maker System est interdite, sauf pour mentionner
                    votre utilisation de la plateforme.
                </p>
                <p>
                    9.3. Les parties s'engagent à considérer comme confidentielles et à ne pas divulguer à des tiers, dans la mesure permise par la loi,
                    toutes les informations échangées entre elles, pendant la durée du contrat et pendant les cinq (5) années suivant sa résiliation. Toutefois,
                    ne sont pas considérées comme confidentielles les informations déjà accessibles au public, celles détenues légalement par une partie avant leur
                    divulgation par l'autre partie, et les informations obtenues d'un tiers autorisé à les divulguer.
                </p>
                <p>
                    9.4. Afin de protéger les informations confidentielles de l'autre partie, chaque partie s'engage à mettre en place des mesures de protection minimales
                    qu'elle utiliserait pour protéger ses propres informations confidentielles. De plus, elle s'assure que ses employés,
                    prestataires de services et sous-traitants éventuels ayant accès aux informations confidentielles signent, avant toute divulgation,
                    un accord de confidentialité dont les obligations sont équivalentes à celles stipulées dans cet article.
                </p>


                <h2>10. Données personnelles</h2>
                <p>
                    10.1. Maker System traite les données personnelles en relation avec votre utilisation de la plateforme ou votre visite sur le site internet de Maker System,
                    conformément à la politique de confidentialité de Maker System.
                </p>
                <p>
                    10.2. Vous êtes l'unique responsable du traitement des données personnelles des utilisateurs de vos applications et des tiers dont vous choisissez de traiter
                    les données via la plateforme.
                </p>


                <h2>11. Durée</h2>
                <p>
                    Le contrat est conclu pour une durée indéterminée.
                </p>
                <p>
                    Le client peut résilier le contrat à tout moment en donnant un préavis de 30 jours par courrier électronique à administratif@maker-system.com,
                    tandis que Maker System peut résilier le contrat en donnant un préavis de 2 (deux) mois par courrier électronique au client
                    (sauf en cas de résiliation pour faute du client). La suppression du compte à la demande du client via la plateforme entraîne la résiliation du contrat.
                </p>


                <h2>12. Effets de la résiliation / réversibilité des données</h2>
                <p>
                    12.1. En cas de résiliation du Contrat dans les conditions prévues à l’article 11, Maker System pourra suspendre définitivement tous les droits d’utilisation et
                    d’accès à la Plateforme à la date de fin de Contrat.
                </p>
                <p>
                    12.2. En cas de résiliation du Contrat, le Client bénéficie du droit de recevoir ou télécharger une copie de ses données stockées sur la Plateforme,
                    telles que les codes sources et bases de données, dans un format ouvert ou standard ne nécessitant pas l’accès à la Plateforme pour être réutilisables.
                    Ce droit peut être exercé pendant 60 (soixante) jours suivant la date de fin du Contrat, en envoyant un e-mail à l’adresse support@clever-cloud.com.
                    Il peut faire l’objet d’une facturation à un tarif convenu avec le Client, proportionné aux ressources nécessaires à l’exercice de ce droit.
                </p>
                <p>
                    12.3. À l’issue du délai d’exercice du droit de réversibilité des données, Maker System dispose du droit de supprimer intégralement les données associées au
                    compte du Client, notamment les codes sources et bases de données.
                </p>


                <h2>13. Modifications et notifications</h2>
                <p>
                    13.1. Maker System se réserve le droit de modifier les conditions à tout moment et sans préavis.
                    Les modifications vous seront communiquées par des moyens appropriés et proportionnés
                    (tels qu'un message affiché dans la console de la plateforme ou un courriel à l'adresse que vous avez fournie lors de votre inscription sur la plateforme).
                    Il vous incombe de consulter régulièrement la plateforme et les communications associées pour être informé des modifications signalées.
                    Toute utilisation continue de la plateforme implique l'acceptation de ces modifications.
                </p>
                <p>
                    13.2. En cas de modification substantielle des conditions, sauf circonstance exceptionnelle ne permettant pas de respecter un tel délai,
                    la modification ne prendra effet qu'à une date ultérieure d'au moins trente (30) jours après la notification.
                    Vous avez le droit de résilier le contrat sans préavis ni indemnité jusqu'à la date d'entrée en vigueur des nouvelles conditions.
                    Toute utilisation continue de la plateforme après cette date implique l'acceptation de ces modifications.
                </p>


                <h2>14. Non-sollicitation de personnel</h2>
                <p>
                    14.1. Sauf accord contraire exprès entre les parties, le client renonce à engager l'un des employés de Maker System qui a participé directement ou
                    indirectement à l'exécution du contrat, même si la sollicitation initiale vient de l'employé concerné. Cette obligation est en vigueur pendant toute
                    la durée du contrat et les douze (12) mois suivant son expiration ou sa résiliation, quelle qu'en soit la cause ou le fondement.
                </p>
                <p>
                    14.2. Si le client ne respecte pas cette interdiction, il s'engage à indemniser l'autre partie (notamment pour les dépenses de sélection et de recrutement,
                    les frais de formation, les dommages résultant de sa réputation personnelle ou des engagements déjà pris en compte) en versant immédiatement une indemnité
                    forfaitaire équivalente à douze (12) fois le dernier salaire brut mensuel perçu par l'employé concerné de la partie en faute.
                </p>


                <h2>15. Autonomie des stipulations</h2>
                <p>
                    15.1. Si une disposition du contrat venait à être jugée nulle ou non écrite par une décision de justice ayant autorité et passée en force de chose jugée,
                    les parties conviennent de tenter de limiter, autant que possible, la portée de cette nullité, de manière que les autres dispositions contractuelles restent
                    en vigueur et que l'équilibre économique du contrat soit respecté.
                </p>


                <h2>16. Loi applicable, attribution de compétence et convention de preuves</h2>
                <p>
                    16.1. Le lieu d'exécution du contrat est le siège social de Maker System, situé à Nantes (France).
                    Le contrat est régi par le droit français, pour les règles de forme comme de fond. En cas de litige concernant l'interprétation,
                    l'exécution ou la résiliation du contrat, les parties s'efforceront de trouver un accord à l'amiable.
                    Si aucun accord n'est trouvé dans les 15 (quinze) jours ouvrables suivant la notification du litige par lettre recommandée avec accusé de réception adressée au
                    siège social de la partie concernée, LA COMPÉTENCE EST EXPRESSÉMENT ATTRIBUÉE AU TRIBUNAL COMPÉTENT DE NANTES, MÊME POUR LES PROCÉDURES D'URGENCE,
                    sauf si une compétence matérielle ou territoriale spécifique ne peut être contractuellement dérogée.
                </p>
                <p>
                    16.2. Le client reconnaît que, dans la mesure où ils concernent le litige en cours,
                    les journaux (fichiers logs) et autres documents numériques conservés par Maker System sont considérés comme preuve, sauf preuve contraire,
                    pour établir la réalité des actions effectuées par les parties.
                </p>
            </Box>
        </>
    );
}

export default Terms;
