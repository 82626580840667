export const color = {
    primary: '#6F61F0FF',
    primaryOpacity: 'rgba(115,102,240,0.10)',
    secondary: '#DB1EE8',
    primaryHover: '#6358d0',
    backgroundPrimary: '#303349',
    backgroundSecondary: '#26293D',
    textPrimary: '#CFD3EC',
    textSecondary: '#B6BFE3',
    textTertiary: '#9C9EB0',
    textError: '#EA5455',
    errorOpacity: 'rgba(234,84,85,0.10)',
    textSuccess: '#49934B',
    tableTable: '#434A68',
};
