import {BrowserRouter, Link, Navigate, Route, Routes} from "react-router-dom";
import Home from "./View/Home";
import Privacy from "./View/Privacy";
import Terms from "./View/Terms";
import Contact from "./View/Contact";
import {AppBar, Box, Divider, Grid, Toolbar, Typography, useMediaQuery} from "@mui/material";
import {color} from "src/Theme";
import React from "react";
import {styled} from "@mui/material/styles";
import ButtonComponent from "./Component/ButtonComponent";
import {useTheme} from "@mui/system";

const LinkStyled = styled(Link)(() => ({
    fontSize: '16px',
    textDecoration: 'none',
    color: color.textPrimary
}));

function App() {
    const theme = useTheme();
    const hidden = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box sx={{
            paddingTop: '64px',
            backgroundColor: color.backgroundSecondary,
            position: 'absolute',
            height: '100%',
            width: '100%',
            margin: 0,
            padding: 0,
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            overflow: 'hidden',
            boxSizing: 'border-box'
        }}>
            <Box className={'scrollable'} sx={{
                position: 'absolute',
                height: '100%',
                width: '100%',
                margin: 0,
                padding: 0,
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                boxSizing: 'border-box',
                paddingTop: '69px',
                overflow: 'scroll'
            }}>
                <BrowserRouter>
                    <AppBar sx={{backgroundColor: color.backgroundPrimary}}>
                        <Toolbar>
                            <Box sx={{width: '100%', maxWidth: '1550px', margin: 'auto'}}>
                                <LinkStyled to={'/'}>
                                    <img width="50" height="47" src="/images/logo.webp" alt="Logo" style={{display: 'inline-block', margin: '11px 15px 8px'}}/>
                                    <Typography sx={{fontSize: hidden ? '15px' : '22px', display: 'inline-block', lineHeight: '69px', verticalAlign: 'bottom'}}>
                                        Maker System
                                    </Typography>
                                </LinkStyled>

                                <Box sx={{width: hidden ? '125px' : '150px', float: 'right', margin: hidden ? '20px 0' : '16px'}}>
                                    <a href="https://app.maker-system.com">
                                        <ButtonComponent label={'Se connecter'} fontSize={hidden ? '10px' : '14px'}/>
                                    </a>
                                </Box>
                            </Box>
                        </Toolbar>
                    </AppBar>

                    <Routes>
                        <Route path={'/'} element={<Home/>}/>;
                        <Route path={'/privacy'} element={<Privacy/>}/>;
                        <Route path={'/terms'} element={<Terms/>}/>;
                        <Route path={'/contact'} element={<Contact/>}/>;
                        <Route path="*" element={<Navigate to={'/'} replace/>}/>
                    </Routes>

                    <br/>

                    <Box sx={{maxWidth: '1550px', margin: 'auto', padding: '0 15px 25px'}}>
                        <Divider sx={{background: color.textPrimary}}/><br/>

                        <Grid container spacing={2}>
                            {!hidden && <Grid item sm={12} md={3} sx={{textAlign: hidden ? 'center' : 'start'}}>
                                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <LinkStyled to={'/'}>
                                        <img width="50" height="47" src="/images/logo.webp" alt="Logo" style={{display: 'inline-block', margin: '11px 15px 8px'}}/>
                                    </LinkStyled>
                                    <Typography sx={{color: color.textPrimary, fontSize: '15px'}}>© 2023 Maker System. <br/> Tous les droits sont réservés.</Typography>
                                </Box>
                                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img width="270" height="59" src="/images/ovh-partner.webp" alt="Logo" style={{display: 'inline-block', margin: '11px 15px 8px'}}/>
                                </Box>
                            </Grid>}

                            <Grid item xs={12} sm={4} md={3} sx={{textAlign: hidden ? 'center' : 'start'}}>
                                <Typography sx={{fontWeight: 700, fontSize: '18px', color: color.textPrimary}}>Envie d'aller plus loin ?</Typography><br/>
                                <LinkStyled to={'/contact'}>Contactez-nous</LinkStyled><br/>
                            </Grid>

                            <Grid item xs={12} sm={4} md={3} sx={{textAlign: hidden ? 'center' : 'start'}}>
                                <Typography sx={{fontWeight: 700, fontSize: '18px', color: color.textPrimary}}>Informations Légales</Typography><br/>
                                <LinkStyled to={'/terms'}>Conditions d'utilisation</LinkStyled><br/><br/>
                                <LinkStyled to={'/privacy'}>Politique de confidentialité</LinkStyled>
                            </Grid>

                            <Grid item xs={12} sm={4} md={3} sx={{textAlign: hidden ? 'center' : 'start'}}>
                                <Typography sx={{fontWeight: 700, fontSize: '18px', color: color.textPrimary}}>Service</Typography><br/>
                                <LinkStyled to={'https://app.maker-system.com/'}>Se connecter</LinkStyled><br/><br/>
                            </Grid>

                            {hidden && <Grid item xs={12} sm={12} md={3}>
                                <Box sx={{textAlign: 'center'}}>
                                    <LinkStyled to={'/'}>
                                        <img width="50" height="47" src="/images/logo.webp" alt="Logo" style={{display: 'inline-block', margin: '11px 15px 8px'}}/><br/>
                                    </LinkStyled>
                                    <Typography sx={{color: color.textPrimary, fontSize: '15px'}}>© 2023 Maker System. Tous les droits sont réservés.</Typography>
                                    <img width="270" height="59" src="/images/ovh-partner.webp" alt="Logo" style={{display: 'inline-block', margin: '11px 15px 8px'}}/>
                                </Box>
                            </Grid>}
                        </Grid>

                        <br/>
                    </Box>
                </BrowserRouter>
            </Box>
        </Box>
    );
}

export default App;
