import React from 'react';
import {Box} from "@mui/system";
import {color} from "src/Theme";
import {Helmet} from "react-helmet";
import {Alert, Checkbox, FormControlLabel, Grid, Typography, Fade} from "@mui/material";
import TextFieldComponent from "../Component/TextFieldComponent";
import ButtonComponent from "../Component/ButtonComponent";
import {formHandlerInit} from "../Handler/FormHandler";

function Contact() {

    const [loading, setLoading] = React.useState(false);
    const [disabled, setDisabled] = React.useState(true);
    const [isSuccess, setIsSuccess] = React.useState(false);
    const [error, setError] = React.useState('');

    // Form
    const [form, setForm] = React.useState({
        email: {
            id: 'email',
            label: 'Email',
            helperText: 'Entrez votre email.',
            type: 'text',
            value: '',
            options: {validation: ['required', 'email']}
        },
        firstname: {
            id: 'firstname',
            label: 'Prénom',
            helperText: 'Entrez votre prénom.',
            type: 'text',
            value: '',
            options: {validation: ['required']}
        },
        lastname: {
            id: 'lastname',
            label: 'Nom',
            helperText: 'Entrez votre nom.',
            type: 'text',
            value: '',
            options: {validation: ['required']}
        },
        company: {
            id: 'company',
            label: 'Société',
            helperText: 'Entrez le nom de votre société.',
            type: 'text',
            value: '',
            options: {}
        },
        message: {
            id: 'message',
            label: 'Message',
            helperText: 'Entrez votre message.',
            type: 'text',
            value: '',
            options: {validation: ['required']}
        }
    });
    const handler = formHandlerInit(form, setForm);
    const send = async (e) => {
        e.preventDefault();

        if (handler.checkValid() < 1) {
            handler.setLoading(true);
            setLoading(true);
            setIsSuccess(false);
            setError('');

            const response = await fetch('https://app.maker-system.com/api/form/contact', {
                method: 'POST',
                body: JSON.stringify({
                    firstname: handler.getValue('firstname'),
                    lastname: handler.getValue('lastname'),
                    email: handler.getValue('email'),
                    company: handler.getValue('company'),
                    message: handler.getValue('message'),
                }),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                setIsSuccess(true);
                handler.reset();
            }
            else {
                setError('Oups ! Une erreur inattendue s\'est produite.');
            }
            handler.setLoading(false);
            setLoading(false);
        }
    };

    React.useEffect(() => {
        let element = document.getElementsByClassName("scrollable");
        element[0].scrollTo({top: 0, behavior: 'auto'});
    }, []);

    return (
        <>
            <Helmet>
                <title>Contactez-nous</title>
                <meta name="description" content="Contactez-nous et écrivons ensemble votre histoire."/>
                <meta name="keywords" content="hébergement web, hébergeur, makersystem, pro, professionnel, AI, IA, intelligence artificielle, serveur, site internet, nom de domaine, support, sécurité"/>
            </Helmet>

            <Fade in={true} {...{timeout: 750}}>
                <Box id={'contact'} sx={{minHeight: 'calc(100vh - 69px)', display: 'grid', alignItems: 'center', padding: '0 15px'}}>
                    <Box>
                        <Box sx={{
                            maxWidth: '1550px', margin: 'auto', padding: '25px', borderRadius: '6px',
                            background: color.backgroundPrimary,
                            height: '100%',
                            boxShadow: 'rgba(12, 16, 27, 0.15) 0px 3px 9px 1px, rgba(12, 16, 27, 0.1) 0px 8px 9px 0px, rgba(12, 16, 27, 0.08) 0px 1px 6px 4px',
                            boxSizing: 'border-box'
                        }}>

                            <Box sx={{margin: '25px -25px', textAlign: 'center', display: 'block', background: color.primaryOpacity, position: 'relative', minHeight: 170}}>
                                <Box sx={{maxWidth: '1550px', margin: 'auto'}}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={6} sx={{display: 'grid', alignItems: 'center'}}>
                                            <Typography component={'h1'} sx={{
                                                textAlign: 'center',
                                                fontSize: '35px',
                                                color: color.primary,
                                                marginBottom: '20px'
                                            }}>Contactez-nous</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <img className={'img-contact'} width={176} height={200} src="/images/girl-with-laptop.webp" alt="girl with laptop"/>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>

                            <Box sx={{maxWidth: 950, margin: 'auto'}}>
                                <form noValidate autoComplete='current-password' onSubmit={send}>

                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <TextFieldComponent handler={handler} id={'firstname'}/>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <TextFieldComponent handler={handler} id={'lastname'}/>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <TextFieldComponent handler={handler} id={'email'}/>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <TextFieldComponent handler={handler} id={'company'}/>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <TextFieldComponent handler={handler} id={'message'} multiline={true}/>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <FormControlLabel sx={{color: color.textPrimary, marginTop: '5px'}} control={
                                                <Checkbox
                                                    onChange={(event) => setDisabled(!event.target.checked)}
                                                    sx={{
                                                        color: color.primary,
                                                        '&.Mui-checked': {
                                                            color: color.primary,
                                                        },
                                                    }}
                                                />
                                            } label={<Typography component={'span'} sx={{fontSize: '13px'}}>J'accepte que les informations fournies soient utilisées conformément à la Réglementation Générale sur la Protection des Données (RGPD) afin de traiter ma demande. Pour plus d'informations, veuillez consulter notre politique de confidentialité.</Typography>}/>
                                        </Grid>
                                    </Grid>

                                    {isSuccess && <><br/><br/><Alert sx={{opacity: 0.5}} severity="success">
                                        Nous avons bien reçu votre demande et nous nous engageons à vous répondre dans les plus brefs délais.
                                    </Alert><br/></>}
                                    {error && <><br/><br/><Alert sx={{opacity: 0.5}} severity="error">{error}</Alert><br/></>}

                                    <Box sx={{width: '100%', marginTop: '25px'}}>
                                        <ButtonComponent label={'Envoyer'} type={'submit'} loading={loading} disabled={disabled}/>
                                    </Box>
                                </form>
                            </Box>

                        </Box>
                    </Box>
                </Box>
            </Fade>
        </>
    );
}

export default Contact;
